<template>
  <v-container class="white d-flex flex-column align-center py-14">
    <v-row
      ><v-col>
        <v-icon color="success" dark size="100px">mdi-checkbox-marked-circle</v-icon>
      </v-col></v-row
    >
    <v-row
      ><v-col>
        <p>
          {{ $t('components.registrationSuccessful.text') }}
        </p>
      </v-col></v-row
    >
    <v-row
      ><v-col>
        <primary-button :to="{ name: 'Login' }">{{ $t('helpers.logIn') }}</primary-button>
      </v-col></v-row
    >
  </v-container>
</template>
<script>
import PrimaryButton from '../components/buttons/PrimaryButton.vue';
export default {
  name: 'RegistrationSuccessful',
  data() {
    return {
      isActivated: false,
    };
  },
  components: { PrimaryButton },

  created() {
    const success = this.$route.query.success;
    if (success) {
      this.isActivated = true;
    }
  },
};
</script>
<style lang="scss" scoped></style>
