<template>
  <div>
    <p>{{ $t('components.activate') }}</p>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
  </div>
</template>
<script>
import AuthService from '../../services/AuthService';

export default {
  name: 'Thanks',
  data() {
    return {
      isActivated: false,
      loading: true,
    };
  },
  methods: {
    async activateUser(userId) {
      this.loading = true;
      try {
        const response = await AuthService.activateCompanyUser(userId);

        if (response.status === 'success') {
          this.$router.push({ name: 'ActivationSuccessful', query: { success: true } });
        }
      } catch (error) {
        this.$router.push({ name: 'ActivationSuccessful', query: { success: false } });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    const userId = this.$route.params.userId;

    if (!userId) {
      this.isActivated = false;
      this.$router.push({ name: 'ActivationSuccessful', query: { success: false } });
    } else {
      this.activateUser(userId);
    }
  },
};
</script>
<style lang="scss" scoped></style>
