<template>
  <v-dialog
    transition="dialog-bottom-transition"
    class="rounded-0"
    v-model="open"
    @click:outside="close"
    persistend
    max-width="600"
  >
    <v-card class="text-center pt-4 pb-6 px-8" tile>
      <div class="text-right">
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <h2 class="card-title primary--text">{{ $t('statementModal.header') }}</h2>
      <p class="font-weight-light mt-2">{{ $t('statementModal.text') }}</p>
      <p>
        <a :href="statementUrl" target="_blank">{{ $t('statementModal.statementLink') }}</a>
      </p>
      <div class="text-left mb-4">
        <v-checkbox v-model="statementChecked" hide-details class="pa-0 mt-3">
          <template slot="label">
            <div class="mr-3">{{ $t('statementModal.acceptStatement') }} *</div>
          </template>
        </v-checkbox>
      </div>
      <v-divider></v-divider>
      <primary-button
        :disabled="!statementChecked"
        class="mt-4"
        color="primary"
        type="button"
        :onClick="confirmAndLogin"
        >{{ $t('statementModal.confirm') }}</primary-button
      >
      <div class="mt-3 d-flex justify-center align-center">
        <v-icon class="mr-2">$phoneIcon</v-icon>
        <span class="tertiary-text--text"> +48 675 567 677 </span>
      </div>
      <div class="mt-3 d-flex justify-center align-center">
        <v-icon class="mr-2">$mailIcon</v-icon>
        <span class="tertiary-text--text"> kontakt@solitan.pl </span>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from '../buttons/PrimaryButton.vue';
import config from '../../../configuration.json';

export default {
  name: 'StatementModal',
  components: { PrimaryButton },
  emits: ['close', 'confirm'],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statementChecked: false,
      avaiableLangs: ['pl', 'en', 'de'],
    };
  },
  computed: {
    statementUrl() {
      const lang = this.avaiableLangs.find((lang) => lang === this.$i18n.locale);
      return lang ? this.getStatementUrl(lang) : this.getStatementUrl('en');
    },
  },
  methods: {
    confirmAndLogin() {
      this.$emit('confirm');
    },
    close() {
      this.$emit('close');
    },
    getStatementUrl(lang) {
      return `${config.VUE_APP_API_URL.replace('/api/v1', '')}/assets/${lang}-ows.pdf`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
