<template>
  <v-container class="white d-flex flex-column align-center py-14">
    <v-row
      ><v-col
        ><v-icon v-if="isActivated" color="success" dark size="100px"
          >mdi-checkbox-marked-circle</v-icon
        ><v-icon v-else color="error" dark size="100px">mdi-close-circle</v-icon></v-col
      ></v-row
    >
    <v-row
      ><v-col
        ><p v-if="isActivated">{{ $t('components.thanks.success') }}</p>
        <p v-else>{{ $t('components.thanks.fail') }}</p></v-col
      ></v-row
    >
    <v-row
      ><v-col
        ><primary-button v-if="isActivated" :to="{ name: 'Login' }">{{
          $t('helpers.logIn')
        }}</primary-button></v-col
      ></v-row
    >
  </v-container>
</template>
<script>
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
export default {
  name: 'Thanks',
  data() {
    return {
      isActivated: false,
    };
  },
  components: { PrimaryButton },

  created() {
    const success = this.$route.query.success;
    if (success) {
      this.isActivated = true;
    }
  },
};
</script>
<style lang="scss" scoped></style>
