<template>
  <title-wrapper :title="$t('components.activatePassword.title')">
    <v-container class="white pa-10">
      <set-account-password
        :button-text="$t('components.activatePassword.buttonText')"
        :loading="loading"
        v-on:click="activateAccount"
      ></set-account-password>
    </v-container>
  </title-wrapper>
</template>
<script>
import SetAccountPassword from '../../components/SetAccountPassword.vue';
import TitleWrapper from '../../components/TitleWrapper.vue';
import AuthService from '../../services/AuthService';
export default {
  components: { TitleWrapper, SetAccountPassword },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async activateAccount(password) {
      this.loading = true;
      try {
        const userId = this.$route.params.userId;
        await AuthService.activateUser(userId, password);
        this.$router.push({ name: 'ActivationSuccessful', query: { success: true } });
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'ActivationSuccessful', query: { success: false } });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
