<template>
  <title-wrapper :title="$t('helpers.logIn')">
    <login-form @emitUserData="setUserData"></login-form>
    <statement-modal
      :open="isModalOpen"
      @close="close"
      @confirm="confirmAndLogin"
    ></statement-modal>
  </title-wrapper>
</template>
<script>
import LoginForm from '../components/LoginForm.vue';
import TitleWrapper from '../components/TitleWrapper.vue';
import StatementModal from '../components/modals/StatementModal.vue';
import { statementModalIsOpenComputed } from '../store/helper';
import UserService from '../services/UserService';

export default {
  name: 'Login',
  components: { LoginForm, TitleWrapper, StatementModal },
  data() {
    return {
      email: '',
      password: '',
      isModalOpen: false,
    };
  },
  computed: {
    ...statementModalIsOpenComputed,
  },
  watch: {
    statementModalIsOpen(val) {
      this.isModalOpen = val;
    },
  },
  methods: {
    async close() {
      await this.$store.dispatch('hideStatementModal');
    },
    async confirmAndLogin() {
      if (!this.email || !this.password) {
        this.$toasted.global.error({
          message: this.$i18n.t('statementModal.emptyData'),
        });
        return;
      }

      await UserService.acceptStatement(this.email);

      await this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
      });
      this.$store.dispatch('initLocale');
    },
    setUserData(value) {
      this.email = value.email;
      this.password = value.password;
    },
  },
  mounted() {
    this.$store.dispatch('hideStatementModal');
  },
};
</script>
<style lang="scss" scoped></style>
